import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationserviceService {

  private configuration: IServerConfiguration;
  constructor(private http: HttpClient) { }
  loadConfig() {
    return this.http.get<IServerConfiguration>('/api/configuration/ConfigurationData')
      .toPromise()
      .then(result => {
        this.configuration = <IServerConfiguration>(result);
      }, error => console.error(error));
  }
  get apiTimoutSession() {
    return this.configuration.Session;
  }

  get plaidEnviroment() {
    return this.configuration.PlaidEnvironment;
  }

  get plaidPublicKey() {
    return this.configuration.PlaidPublicKey;
  }

  get plaidSecretKey() {
    return this.configuration.PlaidClientSecret;
  }

  get plaidClientKey() {
    return this.configuration.PlaidClientKey;
  }
  // get identityServerAddress() {
  //   return this.configuration.IdentityServerAddress;
  // }
}
export interface IServerConfiguration {
  Session: string;
  PlaidEnvironment: string;
  PlaidPublicKey: string;
  PlaidClientSecret: string;
  PlaidClientKey: string;
}
